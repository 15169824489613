//import AOS from "aos";
import WOW from './wow.min.js'
$(window).on("load", function () {
	$("#landing").fadeOut();
});

$(document).ready(function () {
	// AOS.init();

	new WOW().init();

	if ($("#slick").length > 0) {
		var s1 = $("#slick").slick({
			arrows: true,
			dots: true,
			autoplay: false,
			autoplaySpeed: 5000,
			speed: 3500,
			pauseOnHover: false,
			focusOnSelect: false,
			nextArrow:
				'<a class="slick-next slick-arrow"><i class="fa fa-angle-right"></i></a>',
			prevArrow:
				'<a class="slick-prev slick-arrow"><i class="fa fa-angle-left"></i></a>',
		});

		if (s1 && s1[0].slick.$dots !== null && s1[0].slick.$dots.length < 2) {
			s1[0].slick.$dots.hide();
		}
	}

	// if ($("#carousel").length > 0) {
	// 	var s2 = $("#carousel").slick({
	// 		arrows: false,
	// 		dots: true,
	// 		autoplay: true,
	// 		autoplaySpeed: 3375,
	// 		speed: 2500,
	// 		slidesToShow: 4,
	// 		pauseOnHover: false,
	// 		responsive: [
	// 			{
	// 				breakpoint: 992,
	// 				settings: {
	// 					slidesToShow: 4,
	// 				},
	// 			},
	// 			{
	// 				breakpoint: 768,
	// 				settings: {
	// 					slidesToShow: 1,
	// 				},
	// 			},
	// 		],
	// 	});

	// 	if (s2[0].slick.$dots != "undefined" && s2[0].slick.$dots.length < 2) {
	// 		s2[0].slick.$dots.hide();
	// 	}
	// }
	if ($(".carousel-gallery").length > 0) {
		let galleries = $(".carousel-gallery");
		galleries.each(function(index){
			let slidesToShow = $(this).data('columns') || 1
			$(".carousel-gallery").slick({
				arrows: false,
				dots: true,
				autoplay: true,
				autoplaySpeed: 3375,
				speed: 2500,
				slidesToShow: slidesToShow,
				pauseOnHover: false,
				responsive: [
					{
						breakpoint: 992,
						settings: {
							slidesToShow: slidesToShow,
						},
					},
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 1,
						},
					},
				],
			});

		})
	}	


	// function resizeMI(){

	// 				var oneMI=0;

	// 				$("#photos.main-page .kwatery-item>a").height("auto").each(function(){
	// 					if(oneMI<$(this).height()){
	// 						oneMI=$(this).height();
	// 					}
	// 				}).height(oneMI);

	// }
	// resizeMI();
	// $(window).resize(resizeMI);

	// $.each('.slick-dots', function(){
	// 	var _this = $(this);

	// 	// var cnt = _this.find('li');
	// 	// console.log(cnt);
	// 	// if (cnt.length < 2) {_this.hide(); }

	// });

	$(document).on("click", "#menu a", function (event) {
		// event.preventDefault();
		var $hash = $(this)
			.attr("href")
			.replace(/^.*?(#|$)/, "");

		if ($hash) {
			$("html, body").animate(
				{
					scrollTop: $("#" + $hash).offset().top - 122,
				},
				500,
			);
		}
	});

	var id = $("#single-content, #category-content");
	if (id.length) {
		$("html, body").animate(
			{
				scrollTop: $("#" + id.attr("id")).offset().top - 122,
			},
			500,
		);
	}

	var toggleAffix = function (affixElement, scrollElement, wrapper) {
		var height = affixElement.outerHeight(),
			top = wrapper.offset().top;

		if (scrollElement.scrollTop() >= top + 80) {
			//wrapper.height(height);
			affixElement.addClass("affix");
			affixElement.css("top", "0px");
		} else {
			affixElement.removeClass("affix");
			affixElement.css("top", -scrollElement.scrollTop() + "px");
			//wrapper.height('auto');
		}
	};

	$('[data-afix="affix"]').each(function () {
		var ele = $(this),
			wrapper = $('<div class="div-affix"></div>');

		ele.before(wrapper);
		$(window).on("scroll resize", function () {
			toggleAffix(ele, $(this), wrapper);
		});

		// init
		toggleAffix(ele, $(window), wrapper);
	});

	$(window).scroll(function () {
		var scrollTop = $(this).scrollTop();

		$(".outside").css({
			opacity: function () {
				var elementHeight = $(this).height();
				return 0 + (elementHeight - scrollTop) / elementHeight;
			},
		});
	});
});

// Example starter JavaScript for disabling form submissions if there are invalid fields
(function () {
	"use strict";
	window.addEventListener(
		"load",
		function () {
			// Fetch all the forms we want to apply custom Bootstrap validation styles to
			var forms = document.getElementsByTagName("form");
			// Loop over them and prevent submission
			var validation = Array.prototype.filter.call(forms, function (form) {
				form.addEventListener(
					"submit",
					function (event) {
						if (form.checkValidity() === false) {
							event.preventDefault();
							event.stopPropagation();
						}
						form.classList.add("was-validated");
					},
					false,
				);
			});
		},
		false,
	);
})();

(function (){
	var $btns = $(`[data-class]`)	
	$btns.on({
		click: function () {
			var $this = $(this),
			//  $contentBlock = $('.content_block, .content_list, .content_expparagraph');
					$contentBlock = $('body');


			if (!$this.hasClass('active')) {
				$btns.removeClass('active');
				$this.addClass('active');


				if ($this.hasClass('btnFontSmall')) {
					$contentBlock
							.removeClass('fontNormal fontBig')
							.addClass('fontSmall');
				} else if ($this.hasClass('btnFontNormal')) {
					$contentBlock
							.removeClass('fontSmall fontBig')
							.addClass('fontNormal');
				} else if ($this.hasClass('btnFontBig')) {
					$contentBlock
							.removeClass('fontSmall fontNormal')
							.addClass('fontBig');
				}
			}
		}
	});

})()